import { isMobile, body } from '../utils/environment';
import Attr from '../utils/attributes';
// import { rafTimeout } from '@utils/RAF';
// import { scrollTo } from '@utils/scroll';
import Bound from 'bounds.js';

export class Page {
  constructor(name, app) {
    this.app = app;
    this.name = name;
    this.last = '';
    this.current = '';

    this.bounds = Bound({
      // root: window,
      threshold: 0.0,// 0% of the element should be within the root element (eg: window)
      margins: {
        top: 0,
        bottom: 0,
      },
    });

    this.newAll();
    this.call('new' + this.name);
  }

  init() {
    debug('call init from Page', this.name);
    this.initAll();
    this.call('init' + this.name);
  }
  initHome() {}
  initAll() {}

  // When construct any Page
  newAll() {
    const _this = this;

    // this.current = this.DOM.main.querySelector('#page-content').dataset.routerView;
    // this.last = localStorage.getItem('page.current');
    // this.current = location.href;
    // localStorage.setItem('page.current', this.current);

    if (!isMobile) {

      // Init Cursor
      // => always loaded
      import('./cursor').then(function (module) {
        debug('cursor script is loaded', module);
        _this.Cursor = new module.Cursor('.cursor', _this.app);
        // _this.app.addModule(_this.Cursor);
        _this.app.addPageModule(_this.Cursor);
      });

      // Init HoverList (for menu)
      // => loaded only when we open the big nav
      const menuToggler = document.getElementById('primary-mobile-menu');
      let hoverlistIsLoaded = false;
      menuToggler.addEventListener('click', () => {
        if (!hoverlistIsLoaded) {
          import('./hoverList').then(function (module) {
            debug('hoverList script is loaded', module);
            console.log('hoverList script is loaded', module);
            hoverlistIsLoaded = true;
            _this.HoverList = new module.HoverList('.js-hoverlist', _this.app);
            // _this.app.addModule(_this.HoverList);
            _this.app.addPageModule(_this.HoverList);
          });
        }
      });

      // Init ImageTrail only if we have elements in the page
      // => load only when first elem enter viewport
      const firstImagetrailElem = document.querySelector('.is-style-imagetrail');
      if (firstImagetrailElem) {
        this.bounds.watch(firstImagetrailElem, (ratio) => {
          // debug('enter', ratio);

          import('./imageTrail').then(function (module) {
            debug('imageTrail script is loaded', module);
            console.log('imageTrail script is loaded', module);
            _this.ImageTrail = new module.ImageTrail('.is-style-imagetrail', _this.app);
            // _this.app.addModule(_this.ImageTrail);
            _this.app.addPageModule(_this.ImageTrail);
          });

          _this.bounds.unWatch(firstImagetrailElem);
        }, (ratio) => {});

      }

    }

    // Init RevealOnScroll only if we have elements in the page
    // => load only when first elem enter viewport
    const firstROSElem = document.querySelector('.reveal-on-scroll');
    if (firstROSElem) {
      // this.bounds.watch(firstROSElem, (ratio) => {
      //   // debug('enter', ratio);

        import('./revealOnScroll').then(function (module) {
          debug('RevealOnScroll script is loaded', module);
          _this.ROS = new module.RevealOnScroll('.reveal-on-scroll', _this.app);
          // _this.app.addModule(_this.revealOnScroll);
          _this.app.addPageModule(_this.ROS);
        }).catch((err) => {
          debug(err);
          console.log(err);
          Attr.addClass(body, 'no-ros');
        });

      //   _this.bounds.unWatch(firstROSElem);
      // }, (ratio) => {});
    }

    // Init Lightbox only if we have elements in the page
    // => load only when first elem enter viewport
    // if (
    //   document.querySelectorAll('*[data-image]').length ||
    //   document.querySelectorAll('.lightbox').length
    // ) {}
    const firstLightboxElem = document.querySelector('*[data-image],.lightbox');
    if (firstLightboxElem) {
      this.bounds.watch(firstLightboxElem, (ratio) => {
        // debug('enter', ratio);

        import('./lightbox').then(function (module) {
          debug('lightbox script is loaded', module);
          console.log('lightbox script is loaded', module);
          _this.Lightbox = new module.Lightbox(_this.app);
          // _this.app.addModule(_this.Lightbox);
          _this.app.addPageModule(_this.Lightbox);
        });

        _this.bounds.unWatch(firstLightboxElem);
      }, (ratio) => {});
    }

    // Init Tabs only if we have elements in the page
    const firstTabsElem = document.querySelector('.bao-block-tabs');
    if (firstTabsElem) {
      import('./pageTabs').then(function (module) {
        debug('PageTabs script is loaded', module);
        _this.PageTabs = new module.PageTabs(_this.app);
        // _this.app.addModule(_this.PageTabs);
        _this.app.addPageModule(_this.PageTabs);
      });
    }

  }
  // When construct specific Page...
  // => Home
  newHome() {
    const _this = this;
  }
  // newSingleProjets() {
  // }
  // initSingleProjets() {
  // }

  _onPageReady() {
    // if (this.current === this.last) {
    //   // window.scrollTo(0, this.initScrollPosition);
    //   scrollTo(0, this.app.initScrollPosition);
    // }

    this.call('onPageReady' + this.name);
  }

  call(func) {
    if (typeof this[func] === 'function') {
      this[func]();
    }
  }

}
