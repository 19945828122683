import gsap from 'gsap';

/**
 * File primary-navigation.js.
 *
 * Required to open and close the mobile navigation.
 */

export class Navigation {
  constructor(app) {
    this.app = app;

  	this.wrapper = document.body; // this is the element to which a CSS class is added when a mobile nav menu is open
    this.isOpen = false;
		this.mobileButton = document.getElementById( 'primary-mobile-menu' );
		this.bookingButton = document.getElementById( 'header-booking' );
    this.navMenuEl = document.getElementById( 'site-navigation' );
    this.animating = false;

    this.bookHideTL = gsap.timeline({
      onComplete: () => {
        this.bookingButton.classList.toggle('is-open');
      }
    })
      .to(this.bookingButton, {
        opacity: 0,
        yPercent: 25,
        ease: 'Power2.easeOut',
        duration: .5
      }).pause();

    this.bookShowOpenTL = gsap.timeline()
      .to(this.bookingButton, {
        opacity: 1,
        xPercent: -50,
        yPercent: 0,
        ease: 'Power2.easeOut',
        duration: .8,
        delay: .5,

        startAt: {
          xPercent: -50,
          yPercent: 25
        },
        onComplete: () => {
          this.animating = false;
        }
      }).pause();
    this.bookShowCloseTL = gsap.timeline()
      .to(this.bookingButton, {
        opacity: 1,
        xPercent: 0,
        yPercent: 0,
        ease: 'Power2.easeOut',
        duration: .8,
        delay: .15,

        startAt: {
          xPercent: 0,
          yPercent: 25
        },
        onComplete: () => {
          this.animating = false;
        }
      }).pause();
  }

  init() {
    debug('call init from Navigation');

    // If there's no nav menu, none of this is necessary.
 		if ( ! this.navMenuEl ) {
    	return;
    }

  	/**
		 * Menu Toggle Behaviors
		 *
		 * @param {string} id - The ID.
		 */
		// this.navMenu = ( id ) => {

		// };
		// new this.navMenu( 'primary' );

		if ( this.mobileButton ) {
			this.mobileButton.onclick = () => {
        if (this.animating) return;
        this.toggleNavOpen();
      }
		}
		/**
		 * Trap keyboard navigation in the menu modal.
		 * Adapted from TwentyTwenty
		 */
		document.addEventListener( 'keydown', ( event ) => {
			var modal, elements, selectors, lastEl, firstEl, activeEl, tabKey, shiftKey, escKey;
			if ( ! this.wrapper.classList.contains( 'primary-navigation-open' ) ) {
				return;
			}

			modal = document.querySelector( '.' + 'primary-navigation' );
			selectors = 'input, a, button';
			elements = modal.querySelectorAll( selectors );
			elements = Array.prototype.slice.call( elements );
			tabKey = event.keyCode === 9;
			shiftKey = event.shiftKey;
			escKey = event.keyCode === 27;
			activeEl = document.activeElement; // eslint-disable-line @wordpress/no-global-active-element
			lastEl = elements[ elements.length - 1 ];
			firstEl = elements[0];

			if ( escKey ) {
				event.preventDefault();
				this.wrapper.classList.remove( 'primary-navigation-open', 'lock-scrolling' );
				this.twentytwentyoneToggleAriaExpanded( this.mobileButton );
				this.mobileButton.focus();
			}

			if ( ! shiftKey && tabKey && lastEl === activeEl ) {
				event.preventDefault();
				firstEl.focus();
			}

			if ( shiftKey && tabKey && firstEl === activeEl ) {
				event.preventDefault();
				lastEl.focus();
			}

			// If there are no elements in the menu, don't move the focus
			if ( tabKey && firstEl === lastEl ) {
				event.preventDefault();
			}
		} );

		this.navMenuEl.querySelectorAll( '.menu-wrapper > .menu-item-has-children' ).forEach( ( li ) => {
			li.addEventListener( 'mouseenter', () => {
				li.querySelector( '.sub-menu-toggle' ).setAttribute( 'aria-expanded', 'true' );
				this.twentytwentyoneSubmenuPosition( li );
			} );
			li.addEventListener( 'mouseleave', () => {
				li.querySelector( '.sub-menu-toggle' ).setAttribute( 'aria-expanded', 'false' );
			} );
		} );
  }

	/**
	 * Toggle an attribute's value
	 *
	 * @param {Element} el - The element.
	 * @param {boolean} withListeners - Whether we want to add/remove listeners or not.
	 * @since 1.0.0
	 */
	twentytwentyoneToggleAriaExpanded( el, withListeners ) {
		if ( 'true' !== el.getAttribute( 'aria-expanded' ) ) {
			el.setAttribute( 'aria-expanded', 'true' );
			this.twentytwentyoneSubmenuPosition( el.parentElement );
			if ( withListeners ) {
				document.addEventListener( 'click', this.twentytwentyoneCollapseMenuOnClickOutside );
			}
		} else {
			el.setAttribute( 'aria-expanded', 'false' );
			if ( withListeners ) {
				document.removeEventListener( 'click', this.twentytwentyoneCollapseMenuOnClickOutside );
			}
		}
	}

	twentytwentyoneCollapseMenuOnClickOutside( event ) {
		if ( ! this.navMenuEl.contains( event.target ) ) {
			this.navMenuEl.querySelectorAll( '.sub-menu-toggle' ).forEach( function( button ) {
				button.setAttribute( 'aria-expanded', 'false' );
			} );
		}
	}

	/**
	 * Changes the position of submenus so they always fit the screen horizontally.
	 *
	 * @param {Element} li - The li element.
	 */
	twentytwentyoneSubmenuPosition( li ) {
		var subMenu = li.querySelector( 'ul.sub-menu' ),
			rect,
			right,
			left,
			windowWidth;

		if ( ! subMenu ) {
			return;
		}

		rect = subMenu.getBoundingClientRect();
		right = Math.round( rect.right );
		left = Math.round( rect.left );
		windowWidth = Math.round( window.innerWidth );

		if ( right > windowWidth ) {
			subMenu.classList.add( 'submenu-reposition-right' );
		} else if ( document.body.classList.contains( 'rtl' ) && left < 0 ) {
			subMenu.classList.add( 'submenu-reposition-left' );
		}
	}

	/**
	 * Handle clicks on submenu toggles.
	 *
	 * @param {Element} el - The element.
	 */
	twentytwentyoneExpandSubMenu( el ) { // jshint ignore:line
		// Close other expanded items.
		el.closest( 'nav' ).querySelectorAll( '.sub-menu-toggle' ).forEach( function( button ) {
			if ( button !== el ) {
				button.setAttribute( 'aria-expanded', 'false' );
			}
		} );

		// Toggle aria-expanded on the button.
		this.twentytwentyoneToggleAriaExpanded( el, true );

		// On tab-away collapse the menu.
		el.parentNode.querySelectorAll( 'ul > li:last-child > a' ).forEach( function( linkEl ) {
			linkEl.addEventListener( 'blur', function( event ) {
				if ( ! el.parentNode.contains( event.relatedTarget ) ) {
					el.setAttribute( 'aria-expanded', 'false' );
				}
			} );
		} );
	}

  toggleNavOpen() {
    this.animating = true;
    return new Promise((resolve, reject) => {
      this.isOpen = !this.isOpen;
      this.wrapper.classList.toggle( 'primary-navigation-open' );
      this.wrapper.classList.toggle( 'lock-scrolling' );
      this.twentytwentyoneToggleAriaExpanded( this.mobileButton );
      this.mobileButton.focus();

      this.bookHideTL.restart().then(() => {
        if ( this.isOpen ) {
          this.bookShowOpenTL.restart();
        } else {
          this.bookShowCloseTL.restart();
        }
      });

      setTimeout(function() {
        resolve();
      }, 1000);
    });
  }

	_onPageOut() {
		if (this.wrapper.classList.contains( 'primary-navigation-open' )) {
			// return this.mobileButton.click();
			return this.toggleNavOpen();
		}

    return;
	}
}
