import Bound from 'bounds.js';
import { body, isMobile } from '../utils/environment';
import { translate, transform } from '../utils/transform';
import { lerp, floor, round, map, getRandomFloat } from '../utils/math';
import Attr from '../utils/attributes';
import Is from '../utils/is';

export class SmoothScroll {
  constructor(app) {
    this.app = app;
    this.rAF = null;
    this.items = [];

    // Default value
    this.DOM = {
      // the main element
      main: body,
      // the scrollable element
      // we translate this element when scrolling (y-axis)
      scrollable: window,
    };

    this.scroll = {
      isScrolling: true,
      // target: localStorage.getItem('scroll') || 0,

      // document scroll Y
      docScroll: 0,

      // interpolated value
      previous: 0,
      // current value
      current: 0,
      // amount to interpolate
      ease: 0.1,
      // ease: this.env.isMobile ? 0.35 : 0.075,
      // ease: this.env.isMobile ? 0.35 : 0.1,

      // current value setter
      // in this case the value of the translation will be the same like the document scroll
      setValue: () => this.getScroll(),

      // speed & accel & velo
      speed: 0,
      speedTarget: 0,
      acc: 0,
      velo: 0,
    };

    this.bounds = {
      scrollHeight: 0
    };

    // here we define which property will change as we scroll the page
    // -> we will be translating on the y-axis
    // this.renderedStyles = {
    //   translationY: {
    //     // interpolated value
    //     previous: 0,
    //     // current value
    //     current: 0,
    //     // amount to interpolate
    //     ease: 0.1,
    //     // current value setter
    //     // in this case the value of the translation will be the same like the document scroll
    //     setValue: () => this.scroll.current
    //   }
    // };

    // set the initial values
    this.getScroll();
  }

  init() {
    debug('call init from SmoothScroll');

    this.rAF = null;

    // Init the intersection Observer via Bound
    this.boundary = Bound({
      threashold: 0.0,
      margins: {
        top: 0,
        bottom: 0,
        // top: App.bounds.window_h / -10,
        // bottom: App.bounds.window_h / -10,
      },
    });

    this.DOM = {
        // the main element
        main: document.querySelector('[data-scroll]'),
        // the scrollable element
        // we translate this element when scrolling (y-axis)
        scrollable: document.querySelector('[data-scroll-content]'),
    };

    // the [data-scroll] element's style needs to be modified
    this.setStyles();

    // set the initial values
    this.update();

    // set the body's height
    this.setHeight();
  }

  addScrollItem(item) {
    if ( !(Attr.hasClass(item, 'scroll-not-mobile') && isMobile) ) {
      this.items.push( new ScrollItem(item, this) );
    }
  }

  update() {
    // sets the initial value (no interpolation) - translate the scroll value
    this.scroll.current = this.scroll.previous = this.scroll.setValue();
    // for (const key in this.renderedStyles ) {
    //   this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
    // }

    // translate the scrollable element
    this.layout();
  }

  layout() {
    if ( isMobile ) return;
    // apply all styles changes to scrollable element
    // this.DOM.scrollable.style.transform = `translate3d(0,${-1*this.renderedStyles.translationY.previous}px,0)`;
    // translate(this.DOM.scrollable, 0, -1*this.renderedStyles.translationY.previous, 'px'); // el, x, y, unity
    translate(this.DOM.scrollable, 0, -1*this.scroll.previous, 'px'); // el, x, y, unity

    // this.DOM.scrollable.style.setProperty('--scroll-previous', this.scroll.previous);
    // this.DOM.scrollable.style.setProperty('--scroll-doc', this.scroll.docScroll);
  }

  setStyles() {
    if ( isMobile ) return;
    // the main [data-scroll] needs to "stick" to the screen and not scroll
    // for that we set it to position fixed and overflow hidden
    this.DOM.main.style.position = 'fixed';
    this.DOM.main.style.width = this.DOM.main.style.height = '100%';
    this.DOM.main.style.top = this.DOM.main.style.left = 0;
    // this.DOM.main.style.top = Util.hasClass(App.el.app, 'admin-bar') ? ( App.bounds.window_w > 782 ? 32 : 46 ) : 0;
    this.DOM.main.style.overflow = 'hidden';
  }

  setHeight() {
    if ( isMobile ) return;
    this.bounds.scrollHeight = this.DOM.main.scrollHeight;
    // set the heigh of the body in order to keep the scrollbar on the page
    // body.style.height = `${this.DOM.scrollable.scrollHeight}px`;
    body.style.height = `${this.DOM.main.scrollHeight}px`;
    // body.style.height = `${this.DOM.scrollable.offsetHeight}px`;
  }

  getScroll() {
    this.scroll.docScroll = window.pageYOffset || document.documentElement.scrollTop;

    return this.scroll.docScroll;
  }

  getScrollDoc() {
    return this.scroll.docScroll;
  }
  getScrollTarget() {
    return this.scroll.previous;
  }

  render() {
    if (this.scroll.isScrolling) {
      // debug('render scrolling', this.scroll.docScroll, this.scroll.current, this.scroll.previous);

      // Update speed...
      // this.scroll.speed = Math.abs(this.scroll.current - this.scroll.previous);
      this.scroll.speed = Math.min(Math.abs(this.scroll.current - this.scroll.previous), 200) / 200;
      this.scroll.speedTarget += (this.scroll.speed - this.scroll.speedTarget) * 0.2;
      // debug(this.scroll.speed, this.scroll.speedTarget);

      //... and scroll position
      this.scroll.current = this.scroll.setValue();
      this.scroll.previous = lerp(
        this.scroll.previous,
        this.scroll.current,
        this.scroll.ease
      );
      this.scroll.previous = round(this.scroll.previous, 3);

      if (
        (this.scroll.current > this.scroll.previous && this.scroll.previous > this.scroll.current - 0.01) ||
        (this.scroll.current < this.scroll.previous && this.scroll.previous < this.scroll.current + 0.01)
      ) {
        this.scroll.previous = this.scroll.current;
      }
      else if (this.scroll.previous === this.scroll.docScroll) {
        this.scroll.isScrolling = false;
      }

      // Update Velocity & acceleration
      this.scroll.acc  = floor(this.scroll.speed / this.app.bounds.window_w, 3);
      this.scroll.velo =+ this.scroll.acc;

      // Update previous scroll value
      // this.scroll.previous = this.scroll.current;

      // and translate the scrollable element
      this.layout();

      // and update position for every Scroll Item
      for (const item of this.items) {
        // if the item is inside the viewport call it's render function
        // this will update item's styles, based on the document scroll value and the item's position on the viewport
        if ( item.isVisible ) {
          if ( item.insideViewport ) {
            item.render();
          }
          else {
            item.insideViewport = true;
            item.update();
          }
        }
        else {
          item.insideViewport = false;
        }
      }
    }

    // loop..
    this.requestAnimationFrame();
  }


  on(requestAnimationFrame = true) {
    this.addEvents();

    // start the render loop
    requestAnimationFrame && this.requestAnimationFrame();
  }

  off(cancelAnimationFrame = true) {
    cancelAnimationFrame && this.cancelAnimationFrame();

    this.removeEvents();
  }

  requestAnimationFrame() {
    this.rAF = requestAnimationFrame(this.render.bind(this));
  }

  cancelAnimationFrame() {
    cancelAnimationFrame(this.rAF);
  }

  // destroy() {
  //   this.app.el.body.style.height = ''

  //   this.data = null

  //   this.removeEvents()
  //   this.cancelAnimationFrame()
  // }

  addEvents() {
    // window.onbeforeunload = function() {
    //   window.scrollTo(0, 0);
    // };

    // window.addEventListener('scroll', this._onScroll);
    // on resize reset the body's height
    // window.addEventListener('resize', this.setSize);
  }

  removeEvents() {
    // window.removeEventListener('scroll', this._onScroll);
    // window.removeEventListener('resize', this.setSize);
  }


  _onPageReady() {
    // Get all .js-scroll-item elements an init them
      this.items = [];
    // [...this.DOM.scrollable.querySelectorAll('.js-scroll-item')].forEach( item => this.addScrollItem(item) );
    [...body.querySelectorAll('.js-scroll-item')].forEach( item => this.addScrollItem(item) );

    this.on();
  }

  _onPageLoaded() {
    this.setHeight();
  }

  _onPageOut() {
    this.items = [];
    this.off();
  }

  _onScroll() {
    this.scroll.isScrolling = true;
    this.getScroll();
    // this.scroll.current = this.app.scroll.target;
  }

  _onResize() {
    this.setHeight();

    // for every Scroll Item
    for (const item of this.items) {
      item.resize();
    }
  }
}

class ScrollItem {
  constructor(el, smoothScroll) {
    this.app = smoothScroll.app;
    this.smooth = smoothScroll;

    // the .item element
    this.DOM = {el: el};

    // here we define which property will change as we scroll the page and the item is inside the viewport
    this.renderedStyles = {}

    // Define renderedStyles depends on class
    if ( Attr.hasClass(this.DOM.el, 'js-parallax') ) {
      // in this case we will be:
      // - translating the item
      this.renderedStyles.translationY = {
        previous: 0, // interpolated value
        current: 0, // current value
        ease: 0.1, // amount to interpolate
        fromValue: this.DOM.el.dataset.parallaxFrom ? Number( this.DOM.el.dataset.parallaxFrom ) : Number( getRandomFloat(30,150) ),
        toValue: this.DOM.el.dataset.parallaxTo ? Number( this.DOM.el.dataset.parallaxTo ) : false,
        shouldScrollAllPage: Attr.hasClass(this.DOM.el, 'js-parallax-scroll-page'),
        // current value setter
        setValue: () => {
          let fromValue = this.renderedStyles.translationY.fromValue;
          let toValue = this.renderedStyles.translationY.toValue !== false ? this.renderedStyles.translationY.toValue : -1*fromValue;

          if (isMobile) {
            fromValue = fromValue / 5;
            toValue = toValue / 5;
          }

          let val = map(this.props.top - this.smooth.scroll.previous, this.app.bounds.window_h, -1 * this.props.height, fromValue, toValue);

          if (this.renderedStyles.translationY.shouldScrollAllPage) {
            fromValue = 0;
            toValue = this.app.bounds.window_h * -1;
            // debug(toValue);
            val = map(this.props.top - this.smooth.scroll.previous, 0, -1 * (this.smooth.bounds.scrollHeight - this.app.bounds.window_h), fromValue, toValue);
          }

          // this.DOM.el.style.setProperty('--parallax-from', fromValue);
          // this.DOM.el.style.setProperty('--parallax-to', toValue);

          return fromValue < 0 ? Math.min( Math.max(val, fromValue), toValue ) : Math.max( Math.min(val, fromValue), toValue );
        },
      };

      if (typeof this.DOM.el.dataset.parallaxDirection !== 'undefined' && this.DOM.el.dataset.parallaxDirection === 'horizontal') {
        this.renderedStyles.translationX = this.renderedStyles.translationY;
      }
    }
    if ( Attr.hasClass(this.DOM.el, 'js-image-scale') ) {
      // in this case we will be:
      // - scaling the inner image
      // this.DOM.image = this.DOM.el.querySelector('img');
      this.renderedStyles.imageScale = {
        previous: 0, // interpolated value
        current: 0, // current value
        ease: 0.1, // amount to interpolate
        fromValue: this.DOM.el.dataset.imageScaleFrom ? parseFloat( this.DOM.el.dataset.imageScaleFrom ) : 1,
        toValue: this.DOM.el.dataset.imageScaleTo ? parseFloat( this.DOM.el.dataset.imageScaleTo ) : 1.5,
        shouldScrollAllPage: Attr.hasClass(this.DOM.el, 'js-parallax-scroll-page'),
        // current value setter
        setValue: () => {
          const fromValue = this.renderedStyles.imageScale.fromValue;
          const toValue = this.renderedStyles.imageScale.toValue;
          let val = map(this.props.top - this.smooth.scroll.previous, this.app.bounds.window_h, -1 * this.props.height, fromValue, toValue);

          if (this.renderedStyles.imageScale.shouldScrollAllPage) {
            val = map(this.props.top - this.smooth.scroll.previous, 0, -1 * (this.smooth.bounds.scrollHeight - this.app.bounds.window_h), fromValue, toValue);
          }

          return fromValue > toValue ? Math.max( Math.min(val, fromValue), toValue ) : Math.max( Math.min(val, toValue), fromValue );
        },
      };
    }
    if ( Attr.hasClass(this.DOM.el, 'js-spring') ) {
      // in this case we will be:
      // - spring (translate) the item
      this.renderedStyles.spring = {
        previous: 0, // interpolated value
        current: 0, // current value
        ease: this.DOM.el.dataset.spring ? parseFloat(this.DOM.el.dataset.spring) : 0.1, // amount to interpolate
        // current value setter
        setValue: () => {
          const toValue = 150;
          // const val = this.smooth.scroll.velo * this.smooth.scroll.speed * 0.75;
          const val = this.smooth.scroll.speedTarget * 20.0;
          return Math.min(val, toValue);
        },
      };
    }
    if ( Attr.hasClass(this.DOM.el, 'js-skew') ) {
      // in this case we will be:
      // - skew (transform) the item
      this.renderedStyles.skew = {
        previous: 0, // interpolated value
        current: 0, // current value
        ease: 0.1, // amount to interpolate
        // current value setter
        setValue: () => {
          // const skew = this.smooth.scroll.velo * 7.5;
          // const skew = this.smooth.scroll.velo * this.smooth.scroll.speed * 1.5;
          const skew = this.smooth.scroll.speedTarget * 7.5;
          // debug(skew);
          // const toValue = 30;
          // const fromValue = 0;
          // const val = map(skew, 20, 100, fromValue, toValue);
          // const value = Math.max( Math.min( val, toValue ), fromValue );
          const value = skew;
          // return this.smooth.renderedStyles.translationY.previous < this.smooth.scroll.current ? value : -1*value;
          return this.smooth.scroll.previous < this.smooth.scroll.current ? value : -1*value;
        },
      };
    }

    // gets the item's height and top (relative to the document)
    this.getSize();

    // set the initial values
    this.update();

    // use the IntersectionObserver API to check when the element is inside the viewport
    // only then the element styles will be updated
    this.isVisible = false;
    this.insideViewport = false;

    // this.observer = new IntersectionObserver((entries) => {
    //   entries.forEach(entry => this.isVisible = entry.intersectionRatio > 0);
    // });
    // this.observer.observe(this.DOM.el);

    this.smooth.boundary.watch(this.DOM.el, (ratio) => {
      // debug('enter', this, ratio);
      this.isVisible = true;
      Attr.addClass(this.DOM.el, 'is-visible');
    }, (ratio) => {
      // debug('leave', this);
      this.isVisible = false;
      Attr.removeClass(this.DOM.el, 'is-visible');
    });

    // init/bind events
    this.addEvents();
  }

  update() {
    // this.getSize();

    // sets the initial value (no interpolation)
    for (const key in this.renderedStyles ) {
      this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
    }
    // apply changes/styles
    this.layout();
  }

  getSize() {
    const rect = this.DOM.el.getBoundingClientRect();
    this.props = {
      // item's height
      height: rect.height,
      // offset top relative to the document
      top: this.smooth.scroll.current + rect.top
    };

    // this.DOM.el.style.setProperty('--props-top', this.props.top);
    // this.DOM.el.style.setProperty('--props-height', this.props.height);
  }

  addEvents() {
    // window.addEventListener('resize', () => this.resize());
  }

  resize() {
    // gets the item's height and top (relative to the document)
    this.getSize();
    // on resize reset sizes and update styles
    this.update();
  }

  render() {
    // update the current and interpolated values
    for (const key in this.renderedStyles ) {
      this.renderedStyles[key].current = this.renderedStyles[key].setValue();
      this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
      this.renderedStyles[key].previous = round(this.renderedStyles[key].previous, 3);
    }

    // and apply changes
    this.layout();
  }

  layout() {
    // Define the transform to apply
    let tr = '';

    if (Is.def(this.renderedStyles.imageScale)) {
      tr += `scale3d(${this.renderedStyles.imageScale.previous},${this.renderedStyles.imageScale.previous},1)`;
    }

    if (Is.def(this.renderedStyles.translationX)) {
      if (tr !== '') tr += ' ';
      tr += `translate3d(${this.renderedStyles.translationX.previous}px,0,0)`;
    }
    else if (Is.def(this.renderedStyles.translationY)) {
      if (tr !== '') tr += ' ';
      tr += `translate3d(0,${this.renderedStyles.translationY.previous}px,0)`;
    }
    else if (Is.def(this.renderedStyles.spring)) {
      if (tr !== '') tr += ' ';
      tr += `translate3d(0,${this.renderedStyles.spring.previous}px,0)`;
    }

    if (Is.def(this.renderedStyles.skew)) {
      if (tr !== '') tr += ' ';
      tr += `skewY(${this.renderedStyles.skew.previous}deg)`;
    }

    transform(this.DOM.el, tr);
  }
}
